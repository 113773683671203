/* reset */
* {
  box-sizing: border-box;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
button {
  margin: 0;
  padding: 0;
}
fieldset,
img {
  border: 0 none;
}
dl,
ul,
ol,
menu,
li {
  /* list-style: none; */
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
input,
select,
textarea,
button {
  vertical-align: middle;
}
input::-ms-clear {
  display: none;
}
button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}
body {
  background: #fff;
}
body,
th,
td,
input,
select,
textarea,
button {
  font-size: 14px;
  line-height: 1.5;
  font-family: HelveticaNeue, AppleSDGothicNeo-Regular, tahoma, 'Malgun Gothic', '맑은 고딕', dotum, '돋움', sans-serif;
  color: #333;
}

a {
  color: #666666;
  text-decoration: none;
}
a:active,
a:hover {
  text-decoration: none;
}
a:active {
  background-color: transparent;
}
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
}
/* Form */
textarea {
  border: 1px solid #dbdbdb;
}
select {
  height: 32px;
  font-size: 13px;
  color: #373737;
  border: 1px solid #e9e9e9;
  background: #fff;
  border-radius: 5px;
  outline: none;
}
input[type='tel'],
input[type='time'],
input[type='text'],
input[type='password'],
input[type='search'],
input[type='email'],
input[type='file'],
input[type='url'],
input[type='number'],
input[type='date'],
textarea {
  width: 100%;
  font-size: 14px;
  color: #030303;
  border: none;
  background: #f3f3f3;
  transition: all 0.5s;
  vertical-align: middle;
  outline: none;
}
input::-webkit-input-placeholder {
  color: #030303;
  font-size: 14px;
  line-height: 100%;
}
textarea:focus,
input:focus {
  border: none;
}
main,
header,
section,
nav,
footer,
aside,
article,
figure {
  display: block;
}
html {
  position: relative;
}

body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-family: 'Noto Sans KR', sans-serif;
}